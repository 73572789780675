import React, { useState } from "react"
import styled from "@emotion/styled"
import { Formik } from "formik"
import * as Yup from "yup"
import * as Sentry from "@sentry/browser"

import WeeklyLogo from "../ui/images/weekly"
import { useQueryString } from "@capitaria/capitaria-utms"
import { trackEvent } from "../../helpers/tracker"
import CustomCheckbox from "../ui/custom-checkbox"
import ButtonSubmit from "../ui/button-submit"

const ContainerLeadGenerator = styled.div`
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
`

const Title = styled.h2`
  font-size: 30px;
  line-height: 118.1%;
  color: #363f41;
  max-width: 600px;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 60px;
  padding: 0 20px;
  @media (max-width: 424px) {
    font-size: 24px;
    margin-top: 50px;
    margin-bottom: 30px;
    padding: 0 25px;
  }
`

const ContainerHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  order: 2;
  min-width: 100%;
  @media (min-width: 768px) {
    order: 1;
  }
`

const ContainerImage = styled.div`
  order: 1;
  margin-bottom: 35px;
  @media (min-width: 768px) {
    order: 2;
    padding-top: 10px;
  }
`

const ImageLead = styled.img`
  width: 240px;
  @media (min-width: 400px) {
    width: 320px;
  }
`

const ContainerForm = styled.div`
  order: 3;
  @media (min-width: 768px) {
    margin-top: -15px;
  }
`

const Description = styled.p`
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  margin-bottom: 40px;
  max-width: 295px;
  @media (min-width: 768px) {
    max-width: 380px;
  }
`

const Button = styled.button`
  background: linear-gradient(180deg, #30ab76 0%, #71c552 100%);
  border-radius: 6px;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
  color: white;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  height: 48px;
  line-height: 16px;
  margin-top: 10px;
  padding: 16px;
  text-align: center;
  width: 250px;
  border: none;
  cursor: pointer;
  & :hover {
    background: linear-gradient(180deg, #30ab76 0%, #46a457 100%);
    transition: 0.3s;
  }
  @media (max-width: 768px) {
    font-size: 15.37px;
    line-height: 18px;
  }
`

const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-evenly;
    min-width: 740px;
    flex-wrap: wrap;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 250px;
`

const Label = styled.label`
  font-size: 10px;
  line-height: 14px;
  color: #363f41;
  margin-bottom: 5px;
  &.error {
    color: #f17575;
  }
`

const Input = styled.input`
  background: #ffffff;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  min-height: 34px;
  margin-bottom: 10px;
  padding: 8px;
  &.error {
    border: 1px solid #f17575;
  }
`

const ContainerSuccessful = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  order: 3;
  width: 250px;
  margin: 160px 0;
`

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`

const checkboxFormGroupStyle = {
  marginRight: 0,
  "@media (max-width: 812px)": {
    width: "300px",
  },
}

const checkboxLabelStyle = {
  width: "100%",
  "@media (max-width: 812px)": {
    width: "290px",
  },
  ".error": {
    color: "#f17575",
  },
}

const LeadGenerator = () => {
  const {
    parsedQueryString: {
      mc_origen: mcOrigen,
      country,
      utm_medium: utmMedium,
      utm_source: utmSource,
      utm_campaign: utmCampaign,
      utm_content: utmContent,
      utm_term: utmTerm,
    },
  } = useQueryString()

  const [sendFormsuccessful, setSendFormsuccessful] = useState(false)

  const LeadGeneratorSchema = Yup.object().shape({
    name: Yup.string()
      .min(3)
      .required("Este campo es requerido"),
    lastName: Yup.string()
      .min(3)
      .required("Este campo es requerido"),
    email: Yup.string()
      .email("El email ingresado no es válido")
      .required("Este campo es requerido"),
    phone: Yup.string()
      .required("Este campo es requerido")
      .matches(
        /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\\./0-9]*$/,
        "El número de teléfono solo puede incluir números, -, +, () y espacios"
      )
      .min(
        7,
        "El número de teléfono es muy corto - mínimo, debería tener 7 caracteres"
      ),
    accept: Yup.string().matches(
      /^true$/,
      "Debes aceptar el envio tu información a Capitaria y que se comuniquen conmigo"
    ),
  })

  const onSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      const cmCampaignId = "a031n00000fvmfEAAQ"
      const cmType = "Newsletter"
      const cmStatus = "Registrado"
      const cmTopic = "Weekly Digest"

      const response = await fetch(
        "https://master.backend.capitaria.com/api/weekly-digest",
        {
          body: JSON.stringify({
            givenName: values.name.trim(),
            familyName: values.lastName.trim(),
            email: values.email.trim(),
            phone: values.phone,
            country: country,
            cmOrigin: mcOrigen,
            cmCampaignId,
            cmType,
            cmStatus,
            cmTopic,
            utmMedium,
            utmSource,
            utmCampaign,
            utmContent,
            utmTerms: utmTerm,
          }),
          credentials: "omit",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )

      const data = await response.json()

      if (data) {
        setSendFormsuccessful(true)
        trackEvent("home:lead-generator:weekly", {
          mc_origen: mcOrigen,
        })
      }
    } catch (err) {
      setSubmitting(false)
      Sentry.captureException(err)
    }
  }

  return (
    <ContainerLeadGenerator>
      <Title>
        No te pierdas lo más importante de la semana en nuestro newsletter
      </Title>
      <WrapperForm>
        <ContainerHeader>
          <WeeklyLogo />
          <Description>
            Desde la última IPO hasta el nuevo tweet de Elon Musk. Informativo,
            ingenioso y con todo lo que necesitas para comenzar tu semana.
          </Description>
        </ContainerHeader>
        <ContainerImage>
          <ImageLead src="/lead-generator.png" alt="Weekly" />
        </ContainerImage>
        {!sendFormsuccessful ? (
          <ContainerForm>
            <Formik
              initialValues={{
                name: "",
                lastName: "",
                email: "",
                phone: "",
                accept: "false",
              }}
              validationSchema={LeadGeneratorSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                setFieldValue,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValidating,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Label htmlFor="name">Nombre*</Label>
                  <Input
                    type="text"
                    name="name"
                    className={errors.name && touched.name ? "error" : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  <Label htmlFor="lastName">Apellido*</Label>
                  <Input
                    type="text"
                    name="lastName"
                    className={
                      errors.lastName && touched.lastName ? "error" : ""
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                  />
                  <Label htmlFor="phone">Telefono*</Label>
                  <Input
                    type="tel"
                    name="phone"
                    className={errors.phone && touched.phone ? "error" : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                  />
                  <Label htmlFor="email">Email*</Label>
                  <Input
                    type="email"
                    name="email"
                    className={errors.email && touched.email ? "error" : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />

                  <CheckboxWrapper>
                    <CustomCheckbox
                      label="Acepto enviar mi información a Capitaria y que se comuniquen conmigo"
                      value={values.accept}
                      onChange={value => setFieldValue("accept", value)}
                      onBlur={handleBlur}
                      hasError={errors.accept && touched.accept}
                      errorMessage={errors.accept}
                      formGroupStyle={checkboxFormGroupStyle}
                      labelStyle={checkboxLabelStyle}
                    />
                  </CheckboxWrapper>

                  <ButtonSubmit
                    disabled={isValidating}
                    loading={isSubmitting}
                    className="gtm-btn-weekly"
                    text="SUSCRÍBETE ACÁ"
                  />
                </Form>
              )}
            </Formik>
          </ContainerForm>
        ) : (
          <ContainerSuccessful>
            <div>🎉</div>
            <div>
              Formulario
              <br />
              <strong>enviado con éxito</strong>
            </div>
          </ContainerSuccessful>
        )}
      </WrapperForm>
    </ContainerLeadGenerator>
  )
}

export default LeadGenerator
